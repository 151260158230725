// Keep in sync with com.liquidplanner.backend.util
// Use camel case
export enum FeatureFlag {
  groupCapacity = 'groupCapacity',
  jiraExpandedFiltering = 'jiraExpandedFiltering',
  jiraIntegration = 'jiraIntegration',
  jiraIssueCreation = 'jiraIssueCreation',
  jiraMultiProjectPull = 'jiraMultiProjectPull',
  jiraTempoWowUseCases = 'jiraTempoWowUseCases',
  iterationBucket = 'iterationBucket',
  disablePendo = 'disablePendo',
  widgetQuickFilter = 'widgetQuickFilter',
  showAssignmentGridTypeInPortfolioGrid = 'showAssignmentGridTypeInPortfolioGrid',
  createBranchNameInRelightOnly = 'createBranchNameInRelightOnly',
  jiraParentFilter = 'jiraParentFilter',
  projectNova = 'projectNova',
  projectNovaMlIntegration = 'projectNovaMlIntegration',
  teamBasedPlanning = 'teamBasedPlanning',
  nexusAuthGlobal = 'nexusAuthGlobal',
  useNexusAuth = 'useNexusAuth',
  generalTeamBasedPlanning = 'generalTeamBasedPlanning',
  unifiedNavigation = 'unifiedNavigation',
}
