import { useFormikContext } from 'formik';
import { Dropdown, DropdownProps, Table } from 'semantic-ui-react';

import { EstimationType } from 'daos/external_integration_enums';
import { StoryPointSchemesSortColumn } from 'features/administration/settings/settings_page/story_point_schemes/enums';
import { JiraProjectModalInfo } from 'features/jira_project/modal/common/jira_project_modal_info';
import { JiraProjectModalTableNameCell } from 'features/jira_project/modal/common/jira_project_modal_table_name_cell';
import { JiraProjectModalTableRow } from 'features/jira_project/modal/common/jira_project_modal_table_row';
import { JiraProjectTable } from 'features/jira_project/modal/common/jira_project_table';
import { useJiraProjectModalContext } from 'features/jira_project/modal/jira_project_modal_context';
import { RemainingEstimates } from 'features/jira_project/modal/sections/estimate_mapping_section/remaining_estimates';
import { StoryPointsScheme } from 'features/jira_project/modal/sections/estimate_mapping_section/story_points_scheme';
import { estimationTypeValues } from 'features/jira_project/modal/sections/estimate_mapping_section/utils';
import {
  JiraProjectModalFormFields,
  JiraProjectModalFormValues,
  SchemeDisplayNames,
} from 'features/jira_project/modal/types';

import 'features/jira_project/modal/jira_project_modal.scss';

const EstimateMappingSection = () => {
  const { setSelectedSchemeActionDisplayName, storyPointSchemeOwner } = useJiraProjectModalContext();
  const { values, setFieldValue, setFieldError } = useFormikContext<JiraProjectModalFormValues>();

  const estimationTypeValue = values.estimationType;

  const handleDropdownChange = (_: React.SyntheticEvent<HTMLElement>, { value }: DropdownProps) => {
    if (value === EstimationType.StoryPoints) {
      setSelectedSchemeActionDisplayName(
        storyPointSchemeOwner
          ? storyPointSchemeOwner[StoryPointSchemesSortColumn.SchemeName]
          : SchemeDisplayNames.CreateNewScheme,
      );
    } else {
      setSelectedSchemeActionDisplayName(undefined);
    }
    setFieldValue(JiraProjectModalFormFields.EstimationType, value);
    setFieldValue(JiraProjectModalFormFields.SelectedExistingSchemeToCopy, undefined);
    setFieldValue(JiraProjectModalFormFields.StoryPointSchemeName, undefined);
    setFieldError(JiraProjectModalFormFields.StoryPointSchemeName, undefined);
  };
  return (
    <div className="jira-project-modal__content-estimate-mapping" data-testid="jiraProjectModalEstimateMapping">
      {
        <>
          <JiraProjectModalInfo title="Estimate Mapping*" />
          <JiraProjectTable>
            <JiraProjectModalTableRow>
              <JiraProjectModalTableNameCell content={<span>{'Estimation Type'}</span>} />
              <Table.Cell className="jira-project-modal__content-estimate-mapping-dropdown">
                <Dropdown
                  closeOnChange
                  onChange={handleDropdownChange}
                  options={estimationTypeValues}
                  selection
                  value={estimationTypeValue}
                />
              </Table.Cell>
            </JiraProjectModalTableRow>
          </JiraProjectTable>
        </>
      }
      {estimationTypeValue === EstimationType.StoryPoints && <StoryPointsScheme />}
      {estimationTypeValue === EstimationType.RemainingTime && <RemainingEstimates />}
    </div>
  );
};

export default EstimateMappingSection;
