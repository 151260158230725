import { useLocation } from 'react-router';

import { ScheduleBarColumnProps } from 'containers/shared/custom_column/types';
import LpLink from 'containers/shared/lp_link';
import { ItemType } from 'daos/enums';
import LpPopUp from 'features/common/lp_popup';
import { isAncestorItemScheduleBar } from 'features/common/schedule_bar/helpers';
import { ScheduleBarRenderer } from 'features/common/schedule_bar/renderer';
import { ScheduleBarToolTipRenderer } from 'features/common/schedule_bar/tool_tip';
import {
  getHasClippedEffortForScheduleSummaryItem,
  getIsLateOrLateRisk,
} from 'features/common/schedule_bar/tool_tip/helpers';
import { ScheduleSummaryItem, ScheduleSummaryPickedItemMetrics } from 'features/common/schedule_bar/types';
import { calcItemDataForScheduleBar } from 'features/common/schedule_bar/use_item_data_for_schedule_bar';
import { useItemListWidgetContext } from 'features/dashboards_v2/widget/widgets/list/context';
import { TabNames } from 'features/item_panel/sections/tab_names';
import { calcItemDataForScheduleTooltip, ScheduleHoverStatus } from 'hooks/use_item_data_for_schedule_hover';
import { calcItemDataForStatus, calculateAssignmentBarProps } from 'hooks/use_item_data_for_status';
import { ScheduleViewType } from 'hooks/use_items_date_range';
import { useLocalizedFormats } from 'hooks/use_locale_from_user';
import { useIsItemPlanned } from 'lib/planned_work_helpers';

export const ScheduleBarColumn = (props: ScheduleBarColumnProps) => {
  const { pathname } = useLocation();
  const { formatLocalDate } = useLocalizedFormats();
  const { dateRange } = useItemListWidgetContext();

  const item = props.item;
  const itemMetrics = props.itemMetrics[item.id];

  const parentItem = props.parentItem;
  const parentItemMetrics = props.itemMetrics[parentItem?.id ?? 0];
  const isPlannedItem = useIsItemPlanned(item.id);

  if (!item || !itemMetrics || !dateRange) {
    return null;
  }

  const isAtRisk = getIsLateOrLateRisk(item.late ?? false, item.isLateRisk ?? false);
  const hasClippedEffort = getHasClippedEffortForScheduleSummaryItem(item);

  const {
    isClipped,
    isDone,
    isDoneAfterEffectiveTargetFinish,
    isDoneLate,
    isEffectivelyOnHold,
    isExpectedStartAfterEffectiveTargetFinish,
    isExpectedStartBeforeEffectiveTargetStart,
    isExpectedStartEqualExpectedFinish,
    isLate,
    isLateRisk,
    isOnHold,
    isScheduled,
  } = calcItemDataForStatus({
    doneDate: item.doneDate,
    effectiveTargetFinish: itemMetrics.effectiveTargetFinish,
    effectiveTargetStart: itemMetrics.effectiveTargetStart,
    expectedFinish: item.expectedFinish,
    expectedStart: item.expectedStart,
    folderStatus: item.folderStatus,
    isClipped: hasClippedEffort,
    isLate: !!item.late,
    itemType: item.itemType,
    latestFinish: item.latestFinish,
    packageStatus: item.packageStatus,
    schedulingType: item.taskSchedulingType ?? undefined,
    targetFinish: item.targetFinish,
  });

  const {
    isEffectivelyOnHold: isParentEffectivelyOnHold,
    isOnHold: isParentOnHold,
    isDone: isParentDone,
  } = calculateParentProps({
    item,
    itemMetrics,
    parentItem,
    parentItemMetrics,
  });

  const isTask = item.itemType === ItemType.TASKS;
  const isAssignment = item.itemType === ItemType.ASSIGNMENTS;

  const {
    scheduleBarDateOffsetPercents,
    scheduleBarStyles,
    scheduleBarLabelStyles,
    showRollupEarliestActiveTargetFinish,
    showRollupLatestTargetFinish,
  } = calcItemDataForScheduleBar({
    assignmentEffectiveTargetFinish: null,
    dateRange,
    doneDate: item.doneDate,
    effectiveTargetFinish: itemMetrics.effectiveTargetFinish,
    effectiveTargetStart: itemMetrics.effectiveTargetStart,
    expectedFinish: item.expectedFinish,
    expectedStart: item.expectedStart,
    isAssignmentItem: isAssignment,
    isClipped,
    isDone,
    isDoneAfterEffectiveTargetFinish,
    isEffectivelyOnHold,
    isLate,
    isLateRisk,
    isOnHold,
    isScheduled,
    isTaskItem: isTask,
    itemDateRangeFinish: dateRange.finish,
    itemDateRangeStart: dateRange.start,
    latestFinish: item.latestFinish,
    rollupEarliestActiveTargetFinish: itemMetrics.rollupEarliestActiveTargetFinish,
    rollupLatestTargetFinish: itemMetrics.rollupLatestTargetFinish,
    targetFinish: item.targetFinish,
    targetStart: item.targetStart,
  });

  const isIncomplete =
    calcItemDataForScheduleTooltip({
      doneDate: item.doneDate,
      expectedFinish: item.expectedFinish,
      expectedStart: item.expectedStart,
      folderStatus: item.folderStatus,
      highEffort: item.highEffort,
      isLate,
      isLateRisk,
      itemType: item.itemType,
      latestFinish: item.latestFinish,
      needsAssignment: item.needsAssignment ?? false,
      needsEstimate: item.needsEstimate ?? false,
      hasAssignedOrgUser: item.hasAssignedOrgUser ?? false,
      scheduleDirective: item.scheduleDirective,
      taskStatusSchedulingType: item.taskSchedulingType ?? undefined,
    }) === ScheduleHoverStatus.NotComplete;

  const itemScheduleStatus = calcItemDataForScheduleTooltip({
    doneDate: item.doneDate,
    expectedFinish: item.expectedFinish,
    expectedStart: item.expectedStart,
    folderStatus: item.folderStatus,
    highEffort: item.highEffort,
    isLate,
    isLateRisk,
    itemType: item.itemType,
    latestFinish: item.latestFinish,
    needsAssignment: item.needsAssignment ?? false,
    needsEstimate: item.needsEstimate ?? false,
    hasAssignedOrgUser: item.hasAssignedOrgUser ?? false,
    scheduleDirective: item.scheduleDirective,
    taskStatusSchedulingType: item.taskSchedulingType ?? undefined,
  });

  return (
    <LpPopUp
      trackMouseMovement={true}
      useDefaultStyles={false}
      trigger={
        <LpLink
          to={`#panelId=${props.item.id}&panelSection=${TabNames.Scheduling}Tab`}
          className="custom-column__schedule-bar-cell"
        >
          <ScheduleBarRenderer
            doneDate={item.doneDate}
            effectiveTargetFinish={itemMetrics.effectiveTargetFinish}
            effectiveTargetStart={itemMetrics.effectiveTargetStart}
            expectedFinish={item.expectedFinish}
            expectedStart={item.expectedStart}
            formatDateForDisplay={(day: string) => formatLocalDate(day)}
            highEffort={item.highEffort ?? 0}
            isAncestorItemScheduleBar={isAncestorItemScheduleBar({ itemType: item.itemType, pathname })}
            isAssignment={isAssignment}
            isAssignmentNeedsAttention={item.isAssignmentNeedsAttention ?? false}
            isAssignmentPlaceholder={item.isAssignmentPlaceholder ?? false}
            isAssignmentTrackingOnly={item.isAssignmentTrackingOnly ?? false}
            isClipped={isClipped}
            isDebugMode={false}
            isDone={isDone}
            isDoneAfterEffectiveTargetFinish={isDoneAfterEffectiveTargetFinish}
            isDoneLate={isDoneLate}
            isEffectivelyOnHold={isEffectivelyOnHold}
            isExpectedStartAfterEffectiveTargetFinish={isExpectedStartAfterEffectiveTargetFinish}
            isExpectedStartBeforeEffectiveTargetStart={isExpectedStartBeforeEffectiveTargetStart}
            isExpectedStartEqualExpectedFinish={isExpectedStartEqualExpectedFinish}
            isIncomplete={isIncomplete}
            isLate={isLate}
            isLateRisk={isLateRisk}
            isOnHold={isOnHold}
            isParentEffectivelyOnHold={isParentEffectivelyOnHold}
            isParentOnHold={isParentOnHold}
            isScheduled={isScheduled}
            isTask={isTask}
            itemId={item.id}
            latestFinish={item.latestFinish}
            rollupEarliestActiveTargetFinish={itemMetrics.rollupEarliestActiveTargetFinish}
            rollupLatestTargetFinish={itemMetrics.rollupLatestTargetFinish}
            scheduleBarDateOffsetPercents={scheduleBarDateOffsetPercents}
            scheduleBarLabelStyles={scheduleBarLabelStyles}
            scheduleBarStyles={scheduleBarStyles}
            showDateLabels={false}
            showPredecessor={item.hasPredecessors}
            showRollupDates={false}
            showRollupEarliestActiveTargetFinish={showRollupEarliestActiveTargetFinish}
            showRollupLatestTargetFinish={showRollupLatestTargetFinish}
            showSuccessor={item.hasSuccessors}
            targetFinish={item.targetFinish}
            targetStart={item.targetStart}
            viewType={ScheduleViewType.Column}
            assignedToDoneIteration={item.assignedToDoneIteration}
          />
        </LpLink>
      }
      content={
        <ScheduleBarToolTipRenderer
          doneDate={item.doneDate}
          itemType={item.itemType}
          expectedFinish={item.expectedFinish}
          expectedStart={item.expectedStart}
          latestFinish={item.latestFinish}
          isDone={isDone}
          isOnHold={isOnHold}
          isEffectivelyOnHold={isEffectivelyOnHold}
          highEffort={item.highEffort}
          isParentDone={isParentDone}
          needsAssignment={item.needsAssignment ?? false}
          needsEstimate={item.needsEstimate ?? false}
          isAtRisk={isAtRisk}
          itemScheduleStatus={itemScheduleStatus}
          assignedToDoneIteration={item.assignedToDoneIteration}
          isPlannedItem={isPlannedItem}
        />
      }
    />
  );
};

function calculateParentProps({
  item,
  itemMetrics,
  parentItem,
  parentItemMetrics,
}: {
  item: ScheduleSummaryItem;
  itemMetrics: ScheduleSummaryPickedItemMetrics;
  parentItem: ScheduleSummaryItem | null;
  parentItemMetrics?: ScheduleSummaryPickedItemMetrics;
}) {
  if (!parentItem || !parentItemMetrics) {
    return calculateAssignmentBarProps({
      folderStatus: itemMetrics.folderStatus,
      schedulingType: itemMetrics.taskSchedulingType,
    });
  } else {
    return calcItemDataForStatus({
      doneDate: parentItem.doneDate,
      effectiveTargetFinish: parentItemMetrics.effectiveTargetFinish,
      effectiveTargetStart: parentItemMetrics.effectiveTargetStart,
      expectedFinish: parentItem.expectedFinish,
      expectedStart: parentItem.expectedStart,
      folderStatus: parentItem.folderStatus,
      isClipped: parentItem.clippedEffort !== null,
      isLate: !!parentItem.late,
      itemType: parentItem.itemType,
      latestFinish: parentItem.latestFinish,
      packageStatus: parentItem.packageStatus,
      schedulingType: item.taskSchedulingType ?? undefined,
      targetFinish: parentItem.targetFinish,
    });
  }
}
